/* stylelint-disable stylistic/selector-list-comma-newline-after */

.blog-header-logo {
  font-family: "Playfair Display", Georgia, "Times New Roman",
    serif /*rtl:Amiri, Georgia, "Times New Roman", serif*/;
  font-size: 2.25rem;
}

.blog-header-logo:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", Georgia, "Times New Roman",
    serif /*rtl:Amiri, Georgia, "Times New Roman", serif*/;
}

.flex-auto {
  flex: 0 0 auto;
}

.h-250 {
  height: 250px;
}

@media (min-width: 768px) {
  .h-md-250 {
    height: 250px;
  }
}

/* Pagination */
.blog-pagination {
  margin-bottom: 4rem;
}

/*
   * Blog posts
   */
.blog-post {
  margin-bottom: 1rem;
}

.blog-post-meta {
  margin-bottom: 1.25rem;
  color: #727272;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

/* .b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
} */

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

/* .nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
} */

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.border-bottom-indigo {
  /* padding: 5px; */
  border-bottom-style: ridge;
  /* border-bottom-color: rgb(0, 255, 170); */
  margin-bottom: 10px;
}

/* --------------------------------- */
.course-title {
  background: rgb(40, 40, 43);
}

.course-prompt-img {
  max-width: 70%;
  max-height: 50%;
  margin-top: 23%;
  margin-left: 16%;
}

.course-prompt-div {
  border: white;
  border-style: solid;
  background-image: radial-gradient(rgb(127, 129, 134), rgb(11, 11, 12));
}

/* --------------------------------- */

.title-prompt {
  margin: auto;
  color: aliceblue;
}

.image-title-prompt {
  margin-left: 10%;
}

.course-prompt-background {
  background-color: white;
  position: 'absoulte';
  top: '0';
}

.course-title-accordion {
  font-size: 19px;
  margin-bottom: 8px;
}

.course-sub-title-accordion {
  font-size: 14px;
  font-weight: normal;
}

.course-goal-text-size {
  font-size: 14px;
  /*border: solid;*/
}

.course-goal-li:before {
  content: "\2605";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: calc(var(--icon-space) * -1);
  width: var(--icon-space);
  margin-bottom: 6px;
}

.course-detail-li:before {
  content: "\2724";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: calc(var(--icon-space) * -1);
  width: var(--icon-space);
  margin-bottom: 5px;
}

.button-tutorial{
  font-size: 15px;
  font-family: FontAwesome;
  border-radius: 10px;
  height: 45px;
  background-color: #f5df4e;
  transition-duration: 0.6s;
}
.button-tutorial:hover{
  background-color: #494923;
  color: white;

}

.course-requirement-li:before {
  content: "\203A";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: calc(var(--icon-space) * -1);
  width: var(--icon-space);
  margin-bottom: 5px;
}

.course-content-requirement {
  display: grid;
  grid-template-areas: "requirement" "content";
}

.course-requirement {
  grid-area: requirement;
}

.course-content {
  grid-area: content;
}

.course-target-detail {
  display: grid;
  grid-template-areas: "target" "detail";
}

.course-target {
  grid-area: target;
  padding: 2px;
}

.course-target-learn{
  height: 200px;
  display: table-column;

  /*border: solid;*/
  /*overflow: auto;*/
  /*display: flow;*/
}

.course-detail {
  grid-area: detail;
  padding: 2px;
}

@media only screen and (min-width: 1000px) {
  .course-content-requirement {
    grid-template-columns: 60% 40%;
    grid-template-areas: "content requirement";
  }

  .course-target-detail {
    grid-template-columns: 50% 50%;
    grid-template-areas: "target detail";
  }
}

.course-code-block-component {
  width: 100%;
  height: 100%;
}