.nav-bar-template {
    /* background: linear-gradient(0deg, rgba(40, 40, 43) 0%, rgba(78,245,242,1) 100%); */
    background: linear-gradient(0deg, rgba(40,40,43,1) 0%, rgba(245,223,78,1) 100%);
    font-family: Arial, Helvetica, sans-serif;
    
    /* height: 60px;
     */
    
    /* border: solid;
    border-color: yellow; */
    /* position: absolute; */
}


.white-text-nav {
    color: white;
    font-style: normal;
}
.black-text-nav {
    color: white;
    font-style: normal;
}


.nav-bar-collapse {
    background: rgba(40,40,43,1);
    color: pink;
}

.router-nav-stye {
    /* position:absolute; */
}

.navbar-toggler {
  /* width: 25px;
  height: 30px; */
  padding: 10px;
  margin: 18px 15px;
  position: relative;
  border: none;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: block;
}

.div-navbar {
    align-items: center;
    border: solid;
    border-color: black;
}

.nav-brand {
    /* position: absolute; */
    /* border: 3px solid #73AD21; */
    width: 50px;
    height: 50px;
    /* background-color: black; */
    align-items: center;
    justify-content: center;
}

.icon-nav {
    margin-left: 7px;
    /* object-fit: cover;   */
    /* margin-left: 10px; */
}

@media screen and (max-width: 1000px){
    .navStyle ul{
            position: fixed;
            left: 0;
            background: rgba(255, 255, 255, 0.82) !important; 
    }

    .text-nav:hover {
        background-color: rgba(245,223,78,1);
        color: black;
        padding-left: 5px;
        /* border: solid;
        border-color: yellow; */
    }
}

.title-offcanvas {
    color: white;
}