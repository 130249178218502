@import url('https://fonts.googleapis.com/css?family=Audiowide');

/* .navbar-toggler {
   border: none;
   margin-right: 10px;
} */



.home-image {
    background-image: url(../../images/landing/intro-bg.jpg) ;
    background-size: cover;
    -webkit-background-size: 1920px 960px;
    -moz-background-size: cover;
    background-attachment: scroll;
}

.working-me-image {
    background-image: url(../../images/landing/intro-bg-2.jpg);
}

/* .navbar-main {
    background-color: black;
    color: red;
} */

.home-title {
    background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
    font-size: 50px;
}

@keyframes shine {
    0% {
      background-position: 0;
    }
    60% {
      background-position: 950px;
    }
    100% {
      background-position: 950px;
    }
  }

.border {
  border: 15px;
  border-color: rgb(189, 28, 28);
  border-style: solid;
  /* margin: 1px; */
  /* height: 10%; */
  
}

.portfolio-card-image{
  grid-area: img;
  display: flex; 
  justify-content: center;
  align-content: center;
  background-color: transparent;
  background-image: radial-gradient(rgb(127, 129, 134), rgb(11, 11, 12));
}

.portfolio-analytics-image{
  grid-area: img;
  display: flex; 
  justify-content: center;
  align-content: center;
  background-color: transparent;
  /* background-image: radial-gradient(rgb(127, 129, 134), rgb(11, 11, 95)); */
}

.image_courses {
  width:60%;
  margin: auto;
  background-color: transparent;
}

.image_analytics {
  /* width:80%; */
  margin: auto;
  background-color: transparent;
}

.portfolio-card-detail {
  grid-area: detail;
  justify-content: left;
  align-items: center;
  margin: auto;
}

.portfolio-card-body {
  grid-area: body;
}

.portfolio-card{
  display: grid;
  grid-template-areas:
            "img  img" 
            "body detail";
}

@media only screen and (min-width: 600px) {
  .portfolio-card {
    grid-template-columns: 30% 45% 25%;
    grid-template-areas: "img body detail";
  }
}

.stack-badge {
  margin-bottom: 20px;
}

.title-card {
  font-family: "Audiowide", sans-serif;
  font-size: 2em;
}

ul {
  --icon-space: 1.3em;
  list-style: none;
  padding: 0;
}

li {
  padding-left: var(--icon-space);
}

.portfolio-li:before {
  content: "\2666";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: calc( var(--icon-space) * -1 );
  width: var(--icon-space);
}

.carousel {
  /* height: 260px; */
  display: block;
  /* width: fit-content; */
}

.carousel .carousel-item {
  text-align: center;
}

.carousel .carousel-caption {
  position: static;
}

.justify-text {
  text-align: justify;

}


/* ------------------------- */
/*Portfolio Page*/

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@700&display=swap");

:root {
    /* Colors */
    --brand-color: hsl(46, 100%, 50%);
    --black: hsl(0, 0%, 0%);
    --white: hsl(0, 0%, 100%);
    /* Fonts */
    --font-title: "Montserrat", sans-serif;
    --font-text: "Lato", sans-serif;
}

.researching-grid {
    padding: 15px;
    display: inline-grid;
    grid-template-columns: auto;
    gap: 30px;
    justify-content: center;
}

.card-grid {
    display: flex;
    justify-content: center;
}

@media only screen and (min-width: 1100px) {
    .researching-grid {
        gap: 13px;
        grid-template-columns: auto auto auto;
        justify-content: center;
    }
}
@media only screen and (min-width: 750px) and (max-width: 1100px) {
    .researching-grid {
        gap: 13px;
        grid-template-columns: auto auto;
        justify-content: center;
    }
}
/*!* RESET *!*/

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*!* Remove default margin *!*/
/*body,*/
#researching-h2,
#researching-p {
    margin: 0;
}

#researching-h2 {
    font-size: 2.25rem;
    font-family: var(--font-title);
    color: var(--white);
    line-height: 1.1;
}

#researching-p {
    font-family: var(--font-text);
    font-size: 1rem;
    line-height: 1.5;
    color: var(--white);
    margin-top: 15px;
}

.flow > * + * {
    margin-top: var(--flow-space, 1em);
}

/*!* CARD COMPONENT *!*/

.researching-card {
    display: grid;
    place-items: center;
    width: 80vw;
    max-width: 21.875rem;
    height: 28.125rem;
    overflow: hidden;
    border-radius: 0.625rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
}

.researching-card > * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.card__background {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
}

.card__content {
    --flow-space: 0.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-end;
    height: 55%;
    padding: 12% 1.25rem 1.875rem;
    background: linear-gradient(
            180deg,
            hsla(0, 0%, 0%, 0) 0%,
            hsla(0, 0%, 0%, 0.3) 10%,
            hsl(0, 0%, 0%) 100%
    );
}

.card__content--container {
    --flow-space: 1.25rem;
}

.card__title {
    position: relative;
    width: fit-content;
    width: -moz-fit-content; /* Prefijo necesario para Firefox  */
}

.card__title::after {
    content: "";
    position: absolute;
    height: 0.3125rem;
    width: calc(100% + 1.25rem);
    bottom: calc((1.25rem - 0.5rem) * -1);
    left: -1.25rem;
    background-color: var(--brand-color);
}

.card__button {
    padding: 0.75em 1.6em;
    width: fit-content;
    width: -moz-fit-content; /* Prefijo necesario para Firefox  */
    font-variant: small-caps;
    font-weight: bold;
    border-radius: 0.45em;
    border: none;
    background-color: var(--brand-color);
    font-family: var(--font-title);
    font-size: 1.125rem;
    color: var(--black);
}

.card__button:focus {
    outline: 2px solid black;
    outline-offset: -5px;
}

@media (any-hover: hover) and (any-pointer: fine) {
    .card__content {
        transform: translateY(62%);
        transition: transform 500ms ease-out;
        transition-delay: 500ms;
    }

    .card__title::after {
        opacity: 0;
        transform: scaleX(0);
        transition: opacity 1000ms ease-in, transform 500ms ease-out;
        transition-delay: 500ms;
        transform-origin: right;
    }

    .card__background {
        transition: transform 500ms ease-in;
    }

    .card__content--container > :not(.card__title),
    .card__button {
        opacity: 0;
        transition: transform 500ms ease-out, opacity 500ms ease-out;
    }

    .researching-card:hover,
    .researching-card:focus-within {
        transform: scale(1.05);
        transition: transform 500ms ease-in;
    }

    .researching-card:hover .card__content,
    .researching-card:focus-within .card__content {
        transform: translateY(0);
        transition: transform 500ms ease-in;
    }

    .researching-card:focus-within .card__content {
        transition-duration: 0ms;
    }

    .researching-card:hover .card__background,
    .researching-card:focus-within .card__background {
        transform: scale(1.3);
    }

    .researching-card:hover .card__content--container > :not(.card__title),
    .researching-card:hover .card__button,
    .researching-card:focus-within .card__content--container > :not(.card__title),
    .researching-card:focus-within .card__button {
        opacity: 1;
        transition: opacity 500ms ease-in;
        transition-delay: 1000ms;
    }

    .researching-card:hover .card__title::after,
    .researching-card:focus-within .card__title::after {
        opacity: 1;
        transform: scaleX(1);
        transform-origin: left;
        transition: opacity 500ms ease-in, transform 500ms ease-in;
        transition-delay: 500ms;
    }
}