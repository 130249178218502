/* sport analytics dashboard */
/*Layouts*/

.sport-analytics-grid {
  background: rgb(253, 232, 45);
  background: linear-gradient(
    0deg,
    rgba(253, 232, 45, 1) 0%,
    rgba(103, 95, 45, 1) 65%,
    rgba(47, 45, 45, 1) 100%
  );
  gap: 13px;
  padding: 15px;
  display: grid;
  grid-template-areas:
    "title"
    "total_per_year"
    /* "total_per_source" */
    "sub-title"
    "per_country"
    "per_source"
    "per_city";

}

@media only screen and (min-width: 600px) {
  .sport-analytics-grid {
    grid-template-columns: repeat(3, 1fr);
    /*grid-auto-rows: minmax(300px, auto);*/
    gap: 13px;
    grid-template-areas:
      "title title title"
      "total_per_year total_per_year total_per_year"
      "sub-title sub-title sub-title"
      "per_country per_source per_city ";
  }
}

@media only screen and (min-width: 700px) {
  .sport-analytics-sub-title-grid {
    grid-template-columns: 1fr 1fr;
    /*gap: 13px;*/
    grid-template-areas: "sub-title-button sub-title-check";
  }
  .dashboard-sub-title-button {
    display: flex;
    justify-content: flex-end;
  }
  .dashboard-sub-title-check {
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 699px) {
  .sport-analytics-sub-title-grid {
    display: grid;
    grid-template-areas:
      "sub-title-button"
      "sub-title-check";
  }
  .dashboard-sub-title-button {
    display: flex;
    justify-content: center;
  }
  .dashboard-sub-title-check {
    display: flex;
    justify-content: center;
  }
}

.energy_total_per_source-grid {
  display: grid;
  grid-area: total_per_source;
  grid-template-areas:
    "water"
    "solar"
    "wind"
    "plant";
  gap: 10px;
}

@media only screen and (min-width: 900px) {
  .energy_total_per_source-grid {
    grid-template-columns: 1fr 1fr;
    /*gap: 13px;*/
    grid-template-areas:
      "water solar"
      "wind plant";
  }
  svg {
    width: 100%;
    height: 100%;
  }
}

/*******************/
/** containers style **/
.energy_total_per_year {
  grid-area: total_per_year;
  background-color: rgb(223, 223, 223);
}

.energy_per_source {
  grid-area: per_source;
  background-color: rgb(223, 223, 223);
}

.energy_per_country {
  grid-area: per_country;
  background-color: rgb(223, 223, 223);
}

.energy_per_city {
  grid-area: per_city;
  background-color: rgb(223, 223, 223);
}

.sport-analytics-title {
  grid-area: title;
}

.sport-analytics-sub-title {
  grid-area: sub-title;
}

.dashboard-sub-title-check {
  grid-area: sub-title-check;
}

.dashboard-sub-title-button {
  grid-area: sub-title-button;
}

.source-solar {
  grid-area: solar;
  background-color: rgb(223, 223, 223);
}

.source-numbers {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border: solid;*/
}

.source-wind {
  grid-area: wind;
  background-color: rgb(223, 223, 223);
}

.source-plant {
  grid-area: plant;
  background-color: rgb(223, 223, 223);
}

.source-water {
  grid-area: water;
  background-color: rgb(223, 223, 223);
}

.border-areas {
  border-top: 3px;
  border-radius: 10px;
  border-color: grey;
  border-style: solid;
}

/*********************/

.dropdown-continent {
  background-color: rgb(137, 129, 13);
  border-radius: 20px;
  border: solid;
  margin: 5px;
  height: 40px;
  width: 90px;
  font-size: 13px;
  color: yellow;
  /*padding: 10px;*/
}

.dropdown-continent.active{
  background-color: rgb(40, 40, 43);
}

.alineacion {
  margin: auto;
}

.dashboard-title {
  background: rgb(40, 40, 43);
}

.dashboard-title-style {
  color: white;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 2px;
}

.dashboard-sub-title-style {
  border-bottom: solid;
  border-color: black;
  padding-top: 20px;
}

.dashboard-flags {
  text-align: center;
}

table {
  /* border-color: yellow;
  border-style: solid; */
  text-align: left;
}

table tr:hover {
  background-color: black;
  color: white;
}

/* table th {
  background-color: black;
  color: white;
} */

th {
  background-color: rgb(137, 129, 13);
  border: 1px solid rgb(188, 185, 37);
  font-weight: normal;
  text-align: center;
  color: white;

  &:first-of-type {
    text-align: left;
  }
}

.per_source_title {
  height: 15%;
  font-size: 16px;
  display: flex;
  justify-content: left;
  padding-left: 10px;
  align-items: end;
  /*padding-top: 10px;*/
  /*border-bottom: black;*/
  border: solid;
  /*padding: 3%;*/

  /*align-content: center;*/
  /*text-align: center;*/

  /*vertical-align: center;*/

  /*text-align: center;*/
}

.per_source_total {
  display: block;
  text-align: center;
  font-size: 46px;
  font-weight: 600;
  color: #0e76a8;
  font-family: Arial, Helvetica, sans-serif;
}

.per_source_percentage {
  display: block;
  font-size: 16px;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.per_source_footer {
  display: block;
  text-align: center;
  font-size: 16px;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
}
